<template>
    <modal :id="id" title="Approve Video">
        <template #default>
            <div v-if="hasMultipleVideos">
                <p>
                    This will
                    <strong>approve</strong>
                    both videos.
                </p>
                <p class="font-weight-bold">
                    Landscape:
                    <router-link
                        :to="landscapeLink"
                        target="_blank"
                        class="d-inline-block"
                    >
                        {{ announcement.video_pr?.title }}
                    </router-link>
                </p>
                <p class="font-weight-bold">
                    Portrait:
                    <router-link
                        :to="portraitLink"
                        target="_blank"
                        class="d-inline-block"
                    >
                        {{ announcement.portrait_video_pr?.title }}
                    </router-link>
                </p>
                <p>Please ensure you have verified both videos.</p>
            </div>
            <p v-else>
                This will
                <strong>approve</strong>
                the
                <router-link
                    :to="landscapeLink"
                    target="_blank"
                    class="font-weight-bold d-inline-block"
                >
                    {{ announcement.video_pr?.title }}
                </router-link>
                video.
            </p>
            <v-checkbox v-model="includeUbc" label="Include UBC" />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="approve"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import APrioritySelectInput from '@/components/AForm/Inputs/APrioritySelectInput';

import { VideoStatus } from '@/types/Video';
import type { Announcement } from '@/types/Announcement';

@Component({
    components: {
        Modal,
        APrioritySelectInput
    }
})
export default class VideoApprove extends Vue {
    id = 'video-approve';

    includeUbc = true;

    get hasMultipleVideos() {
        return !!this.announcement.portrait_video_pr;
    }

    get landscapeLink() {
        return `/announcements/review/${this.announcement.id}/video`;
    }

    get portraitLink() {
        return `/announcements/review/${this.announcement.id}/video/portrait`;
    }

    @InjectReactive({
        from: 'announcement',
        default() {
            return {
                id: 0
            };
        }
    })
    announcement!: Announcement;

    approve() {
        this.$emit('confirm', {
            action: 'approve',
            data: {
                status: VideoStatus.Approved,
                include_ubc: this.includeUbc
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
