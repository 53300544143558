import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.loading)?_c(VCard,[_c('video-card',{staticClass:"mb-2",attrs:{"elevation":"0","loading":_vm.loading}})],1):_c(VCard,{attrs:{"elevation":hover ? 2 : 0}},[(_vm.hasApprovableLandscape)?_c('video-card',{staticClass:"mb-2",attrs:{"video":_vm.announcement.video_pr}}):_vm._e(),(_vm.hasApprovableLandscape && _vm.hasApprovablePortrait)?_c(VDivider,{staticClass:"mx-4"}):_vm._e(),(_vm.hasApprovablePortrait)?_c('video-card',{staticClass:"mb-2",attrs:{"video":_vm.announcement.portrait_video_pr,"is-portrait":""}}):_vm._e(),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.onAmpAction('approve')}}},[_vm._v(" Approve ")]),_c(VBtn,{staticClass:"red--text",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.onAmpAction('reject')}}},[_vm._v(" Reject ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }