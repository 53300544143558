import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{attrs:{"id":_vm.id,"title":_vm.video.title,"persistent":false,"max-width":"1000px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pa-0 text-center",attrs:{"cols":"12"}},[_c('video',{staticClass:"video-preview",attrs:{"width":_vm.width,"height":_vm.height,"controls":"","loop":"","playsinline":"","src":_vm.src}},[_vm._v(" Your browser does not support the video element. Please update your browser version. ")])])],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c(VSpacer),_c(VBtn,{staticClass:"error--text",attrs:{"block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.onVideoAction('reject')}}},[_vm._v(" Reject ")]),_c(VBtn,{attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.onVideoAction('approve')}}},[_vm._v(" Approve ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }