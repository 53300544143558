<template>
    <v-container>
        <v-row class="my-2 px-3">
            <div class="text-h6 text-md-h5 font-weight-bold">
                Video Approval
            </div>
        </v-row>

        <videos-by-amp-list
            :announcements="announcements"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />

        <video-action-dialogs @update="update" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { ListPaged } from '@/mixins';
import { ProvideReactive } from '@/utils/decorators';

import { Paging } from '@/components/Paging';

import VideosByAmpList from '@/components/VideosByAmpList/VideosByAmpList.vue';

import VideoActionDialogs from './VideoActionDialogs';

import type { AdminVideoAction } from '@/types/VideoPr';
import type { Announcement } from '@/types/Announcement';

@Component({
    components: {
        VideosByAmpList,
        Paging,
        VideoActionDialogs
    }
})
export default class Approval extends mixins(ListPaged) {
    endpoint = '/video_prs/approval';

    announcements: Announcement[] = [];

    onData(data: { length: number; announcements: Announcement[] }) {
        const { announcements, length } = data;

        this.announcements = announcements;

        this.total = length;
    }

    @ProvideReactive()
    announcement: Partial<Announcement> | null = null;

    @ProvideReactive()
    onAction(action: AdminVideoAction, announcement: Announcement) {
        this.announcement = announcement;

        this.$store.dispatch('modal/open', `video-${action}`);
    }
}
</script>
