<template>
    <span>
        <video-approve @confirm="confirm" />
        <video-reject @confirm="confirm" />
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import VideoApprove from './VideoApprove.vue';
import VideoReject from './VideoReject.vue';

import type { Announcement } from '@/types/Announcement';

type ActionPayload = {
    action: string;
    data?: Record<string, string | number | boolean>;
};

@Component({
    components: {
        VideoApprove,
        VideoReject
    }
})
export default class AssignmentActionDialogs extends Vue {
    @InjectReactive({
        from: 'announcement',
        default() {
            return {
                id: 0
            };
        }
    })
    announcement!: Announcement;

    confirm(payload: ActionPayload) {
        this.applyAction(payload).then(() => {
            this.$store.dispatch('notification/success', 'Video updated');

            this.update();
        });
    }

    async applyAction({ data = {} }: ActionPayload) {
        return this.$http
            .post('/video_prs/approval', {
                ...data,
                announcement_id: this.announcement.id
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to update Video. Please try again later or contact support.'
                    );
                }
            });
    }

    update() {
        // ask parent to reload
        this.$emit('update', true);
    }
}
</script>
