<template>
    <v-hover v-slot="{ hover }">
        <v-card v-if="loading">
            <video-card elevation="0" :loading="loading" class="mb-2" />
        </v-card>
        <v-card v-else :elevation="hover ? 2 : 0">
            <video-card
                v-if="hasApprovableLandscape"
                :video="announcement.video_pr"
                class="mb-2"
            />
            <v-divider
                v-if="hasApprovableLandscape && hasApprovablePortrait"
                class="mx-4"
            />
            <video-card
                v-if="hasApprovablePortrait"
                :video="announcement.portrait_video_pr"
                is-portrait
                class="mb-2"
            />
            <v-card-actions class="justify-end">
                <v-btn
                    class="primary"
                    :disabled="loading"
                    @click="onAmpAction('approve')"
                >
                    Approve
                </v-btn>

                <v-btn
                    class="red--text"
                    :disabled="loading"
                    @click="onAmpAction('reject')"
                >
                    Reject
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, ProvideReactive } from '@/utils/decorators';

import VideoCard from './VideoCard.vue';

import { VideoStatus } from '@/types/Video';

import type { Announcement } from '@/types/Announcement';
import type { AdminVideoAction } from '@/types/VideoPr';

const AnnouncementCardOptions = Vue.extend({
    name: 'AnnouncementCard',
    props: {
        announcement: {
            type: Object as PropType<Partial<Announcement>>,
            default() {
                return {
                    id: 0,
                    video_pr: {
                        id: 0,
                        title: '',
                        description: '',
                        tags_array: [],
                        publish_time: null,
                        announcement: null,
                        announcement_id: 0,
                        preview_available: false,
                        status: -1
                    },
                    portrait_video_pr: null
                };
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        VideoCard
    }
})
export default class AnnouncementCard extends AnnouncementCardOptions {
    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (
        action: AdminVideoAction,
        announcement: Partial<Announcement>
    ) => void;

    @ProvideReactive()
    onAmpAction(action: AdminVideoAction) {
        this.onAction(action, this.announcement as Partial<Announcement>);
    }

    get hasApprovableLandscape() {
        return this.announcement.video_pr?.status === VideoStatus.Rendered;
    }

    get hasApprovablePortrait() {
        return (
            this.announcement.portrait_video_pr?.status === VideoStatus.Rendered
        );
    }
}
</script>
