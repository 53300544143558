<template>
    <v-container v-if="isBusy">
        <announcement-card v-for="i in 5" :key="i" loading class="mb-2" />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <announcement-card
            v-for="(announcement, index) in announcements"
            :key="'announcement-card-' + announcement.id + '-' + index"
            :announcement="announcement"
            class="mb-2"
        />
    </v-container>
    <v-container v-else class="px-0">
        <v-row class="mx-0">
            <v-col cols="12" class="d-flex justify-center pt-8 px-0">
                <span>No videos found to approve</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import AnnouncementCard from './AnnouncementCard.vue';

import { Announcement } from '@/types/Announcement';

const VideosListProps = Vue.extend({
    name: 'VideosList',
    props: {
        announcements: {
            type: Array as PropType<Partial<Announcement>[]>,
            default(): Announcement[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AnnouncementCard
    }
})
export default class VideosList extends VideosListProps {
    get isBusy() {
        return this.loading || this.updating;
    }

    get hasData() {
        return Boolean(this.announcements.length);
    }
}
</script>
